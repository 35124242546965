import React from "react";
import { Link } from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";
import forest from "../images/backgrounds/Retul-road-forest.jpg";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
		

			<div className="w-full relative">

				<img src={forest} alt="Skógur" className="object-cover object-center mx-auto" />

				<div
					className="container p-8 max-w-6xl mx-auto sm:absolute sm:inset-0 flex justify-left items-center"
					>

					<div className="w-full">
						<h2 className="text-3xl md:text-5xl xl:text-6xl">Úti að hjóla (404)</h2>

						<p className="text-2xl">Síðan sem þú ert að leita að fannst ekki.</p>

						<p className="text-lg uppercase hover:text-retulBlue"><Link to="/">Fara á forsíðu</Link>
						</p>
					</div>
			</div>
		</div>

    </Layout>
  );
}

export default NotFoundPage;
